<script setup lang="ts">
const { watchTheme } = useThemeStorage();

watchTheme();

useHead({
  titleTemplate: "Mezan Admin | %s",
  title: "App",
});
</script>

<template>
  <Html
    lang="en"
    dir="ltr"
  >
    <NuxtLayout class="font-en">
      <NuxtPwaManifest />
      <NuxtPage />
    </NuxtLayout>
  </Html>
</template>
