import { TRPCClientError } from "@trpc/client";

const online = ref(true);

export const useNetworkCheck = () => {
  const config = useRuntimeConfig();
  const url =
    config.public.API_TARGET !== "staging"
      ? config.public.API_URL
      : config.public.API_URL.replace("http:", "https:").replace("80", "3030");

  const healthURL = url + "/health";

  const isOnline = async () => {
    try {
      const { data, error } = await useFetch<{ live: boolean }>(healthURL);

      if (error.value || !data.value?.live) {
        throw error.value || new Error("Network Error");
      }

      online.value = true;

      return true;
    } catch {
      online.value = false;
      return false;
    }
  };

  const isNetworkError = (error?: unknown): boolean => {
    if (!error) return false;

    // Check if the error is an instance of TRPCClientError
    if (error instanceof TRPCClientError) {
      // Check for specific network error messages or codes
      const networkErrorMessages = [
        "NetworkError",
        "Failed to fetch",
        "Network request failed",
        "TypeError: Failed to fetch",
      ];

      return networkErrorMessages.some((msg) => error.message.includes(msg));
    }

    return false;
  };

  const setOffline = () => {
    online.value = false;
  };

  return {
    online,
    isOnline,
    isNetworkError,
    setOffline,
  };
};
