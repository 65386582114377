import revive_payload_client_B960bfRq64 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dJqE3gbUIP from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_L2iUZEoixF from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_UGoqxlKOxS from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2NJt37nj9Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yIl8yNrkDU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kR8gFBDG13 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_nRVaZIs3ZP from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@2.79.1_typescript@5.6.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/admin/.nuxt/components.plugin.mjs";
import prefetch_client_mR2cyjIYSm from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.16.5_rollup@2.79.1_sass@1.77.8_typescript@5.6.3_vite@5.4.5_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/apps/admin/.nuxt/unocss.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/vercel/path0/apps/admin/.nuxt/pwa-icons-plugin.ts";
import pwa_client_A9EooB6NwT from "/vercel/path0/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_@vite-pwa+assets-generator@0.2.6_rollup@2.79.1_vite@5.4.5_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import i18n_VfGcjrvSkj from "/vercel/path0/apps/admin/plugins/i18n.ts";
import trpc_0KeCZOBKLF from "/vercel/path0/apps/admin/plugins/trpc.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/apps/admin/plugins/vue-query.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/apps/admin/plugins/vuetify.ts";
export default [
  revive_payload_client_B960bfRq64,
  unhead_dJqE3gbUIP,
  router_L2iUZEoixF,
  payload_client_UGoqxlKOxS,
  navigation_repaint_client_2NJt37nj9Z,
  check_outdated_build_client_yIl8yNrkDU,
  chunk_reload_client_kR8gFBDG13,
  plugin_vue3_nRVaZIs3ZP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mR2cyjIYSm,
  unocss_MzCDxu9LMj,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_A9EooB6NwT,
  i18n_VfGcjrvSkj,
  trpc_0KeCZOBKLF,
  vue_query_wrmMkNpEpe,
  vuetify_7h9QAQEssH
]